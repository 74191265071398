import React from "react"
import RandomQuote from "./quote"
import { Link } from "gatsby"

import sealSVG from "../../../scss/img/sib-seal-orange.svg"

const MainFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top has-text-centered">
          <img
            className="seal"
            src={sealSVG}
            alt="Shakespeare in Bits Seal"
          />
          <div className="quote">
            <RandomQuote />
          </div>

          <nav aria-label="footer navigation">
            <ul>
              <li>
                <a href="/educators">For Educators</a>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
            </ul>
          </nav>

          <ul className="social-links">
            <li>
              <a href="https://www.facebook.com/Shakespeare-In-Bits-254232564177">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.092 32H1.766C.79 32 0 31.21 0 30.234V1.766C0 .791.79 0 1.766 0h28.468C31.209 0 32 .79 32 1.766v28.468c0 .975-.79 1.766-1.766 1.766H22.08V19.608h4.159l.623-4.83H22.08v-3.083c0-1.398.388-2.351 2.393-2.351l2.557-.001v-4.32c-.442-.058-1.96-.19-3.726-.19-3.687 0-6.212 2.25-6.212 6.384v3.561h-4.17v4.83h4.17V32z"
                    fillRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/MindConnexNews">
                <svg
                  width="32"
                  height="28"
                  viewBox="0 0 32 28"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32 3.824a12.823 12.823 0 01-3.77 1.059 6.717 6.717 0 002.887-3.723 12.93 12.93 0 01-4.171 1.631A6.474 6.474 0 0022.154.667c-3.623 0-6.563 3.014-6.563 6.731 0 .528.057 1.04.17 1.534C10.304 8.65 5.468 5.973 2.228 1.896a6.854 6.854 0 00-.889 3.387 6.776 6.776 0 002.92 5.603 6.446 6.446 0 01-2.974-.84v.084c0 3.262 2.263 5.984 5.268 6.6a6.26 6.26 0 01-1.73.237c-.422 0-.835-.04-1.236-.12.836 2.674 3.26 4.621 6.133 4.673a12.963 12.963 0 01-8.154 2.884c-.53 0-1.053-.031-1.566-.093a18.279 18.279 0 0010.064 3.022c12.077 0 18.68-10.256 18.68-19.153 0-.292-.007-.585-.018-.872A13.474 13.474 0 0032 3.824"
                    fillRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-bottom">
          <div className="columns is-desktop">
            <div className="column">
              <p>
                &copy; {new Date().getFullYear()} MindConnex Learning. All
                rights reserved.
              </p>
            </div>
            <div className="column has-text-right-desktop">
              <ul className="text-list">
                <li>
                  <Link to="/terms">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default MainFooter
