import React, { useState } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { useIdentityContext } from "../../../services/react-netlify-identity-gotrue"
import { useSubInfoContext } from "../../../services/subinfocontext"

import logoSVG from "../../../scss/img/logo-sib.svg"

import { Link } from "gatsby"

const Navbar = (props) => {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()

  const [isOpen, setIsOpen] = useState(false)

  const toggleCollapse = (e) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  const navTo = (e, dest) => {
    e.preventDefault()
    if (props.hideAllLinks) {
      return
    }
    navigate(dest, {state: {showError: false}})
  }

  let isPaid = subInfoContext.subInfo && !subInfoContext.subInfo.isUnpaid && !subInfoContext.subInfo.isExpired

  let isStudent = subInfoContext.subInfo && subInfoContext.subInfo.isStudentCode

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <a
              className="navbar-item"
              href="/"
              onClick={(e) => {
                navTo(e, "/")
              }}
            >
              <img src={logoSVG} alt="Logo" />
            </a>
            <div
              role="button"
              tabIndex={0}
              className={`navbar-burger ${isOpen ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbar-menu"
              onClick={(e) => {
                toggleCollapse(e)
              }}
              onKeyDown={(e) => {
                toggleCollapse(e)
              }}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>
          <div
            id="navbar-menu"
            className={`navbar-menu ${isOpen ? "is-active" : ""}`}
          >
            <div
              className={`navbar-start navbar-product-info ${
                (isPaid && !props.showFrontendLinks) ||
                isStudent ||
                props.hideAllLinks
                  ? "is-hidden"
                  : ""
              }`}
            >
              <Link className={`navbar-item`} to="/product">
                Product
              </Link>
              <Link className={`navbar-item`} to="/educators">
                For Educators
              </Link>
              <Link className={`navbar-item`} to="/pricing">
                Pricing
              </Link>
            </div>
            <div
              className={`navbar-end ${props.hideAllLinks ? "is-hidden" : ""}`}
            >
              <Link
                className={`navbar-item ${
                  subInfoContext.subInfo && subInfoContext.subInfo.isStudentCode
                    ? ""
                    : "is-hidden"
                }`}
                to="/studenthome"
              >
                Plays
              </Link>
              <Link
                className={`navbar-item ${
                  subInfoContext.subInfo &&
                  subInfoContext.subInfo.role === "admin"
                    ? ""
                    : "is-hidden"
                }`}
                to="/app/admin"
              >
                Admin
              </Link>
              <Link
                className={`navbar-item ${
                  subInfoContext.subInfo &&
                  !subInfoContext.subInfo.isStudentCode
                    ? ""
                    : "is-hidden"
                }`}
                to="/app/userhome"
                onClick={(e) => {
                  toggleCollapse(e)
                  navTo(e, '/app/userhome')
                }}
               >
                Plays
              </Link>

              <Link
                className={`navbar-item ${identity.user ? "" : "is-hidden"}`}
                to="/app/profile"
                onClick={(e) => {
                  toggleCollapse(e)
                  navTo(e, '/app/profile')
                }}
              >
                Account
              </Link>
              <LoginButton
                hideAllLinks={props.hideAllLinks}
                navTo={navTo}
                identity={identity}
                subInfo={subInfoContext.subInfo}
                subInfoContext={subInfoContext}
              />
              <div
                className={`navbar-item ${
                  identity.user || isStudent || props.hideAllLinks
                    ? "is-hidden"
                    : ""
                }`}
              >
                <Link className="button is-black" to="/signup">
                  Sign up
                </Link>
              </div>
              <div className="navbar-item is-hidden-desktop">
                <Link
                  className="support-link has-text-sibredorange"
                  to="/contact"
                >
                  Contact Us!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

const LoginButton = (props) => {
  const handleClick = (e) => {
    e.preventDefault()
    props.identity.logout()
    props.subInfoContext.logout()
    props.navTo(e, "/login")
  }

  return (
    <>
      {props.identity.user ||
      (props.subInfoContext.subInfo && props.subInfo.isStudentCode) ? (
        <a
          tabIndex={0}
          className={`navbar-item ${props.hideAllLinks ? "is-hidden" : ""}`}
          target="_blank"
          rel="noopener noreferrer"
          href="/"
          onClick={handleClick}
          onKeyDown={handleClick}
          id="navbar-logout-button"
        >
          Log out
        </a>
      ) : (
        <a
          role="button"
          tabIndex={0}
          className={`navbar-item ${props.hideAllLinks ? "is-hidden" : ""}`}
          target="_blank"
          rel="noopener noreferrer"
          href="/"
          onClick={(e) => props.navTo(e, "/login")}
          onKeyDown={(e) => props.navTo(e, "/login")}
          id="navbar-login-button"
        >
          Log in
        </a>
      )}
    </>
  )
}

export default connect(
  (state) => ({
    fetchingSubInfo: state.app.fetchingSubInfo,
  }),
  null
)(Navbar)
