/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import MainFooter from "./footer"
import Navbar from "./navbar"

import EmailConfirmation from "../../../components/user/emailconfirmation"

const Layout = (props) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      sib_logo_bw: file(relativePath: { eq: "home-launch-demo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 25, layout: FIXED)
        }
      }
    }
  `)

  return (
    <>
      {/* Wrapper for sticky footer */}
      <div className="site">
        <Navbar
          showFrontendLinks={props.showFrontendLinks}
          siteTitle={data.site.siteMetadata.title}
          logo={data.sib_logo_bw.childImageSharp}
        />
        <EmailConfirmation />
        <main className="site-content">{props.children}</main>
        <MainFooter />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
