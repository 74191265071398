import React from "react"

const RandomQuote = () => {
  const quotes = [
    ["We are such stuff as dreams are made on...", "The Tempest"],
    ["There is nothing good or bad, but thinking makes it so.", "Hamlet"],
    ["The wheel has come full circle: I am here.", "King Lear"],
    ["Some rise by sin, and some by virtue fall.", "Measure for Measure"],
    ["The fault, dear Brutus, is not in our stars.", "Julius Caesar"],
    ["Life's but a walking shadow...", "Macbeth"],
    ["Our doubts are traitors...", "Measure for Measure"],
    ["Time shall unfold what plighted cunning hides.", "King Lear"],
    ["The robb'd that smiles, steals something from the thief.", "Othello"],
    ["But thy eternal summer shall not fade...", "Hamlet"],
    ["Though this be madness, yet there is method in 't.", "Hamlet"],
    ["True it is that we have seen better days.", "As You Like It"],
    ["Now is the winter of our discontent.", "King Richard III"],
    ["Tempt not a desparate man.", "Romeo and Juliet"],
    ["Small things make base men proud.", "King Henry VI"],
    ["Beware the ides of March.", "Julius Caesar"],
    ["What's done is done.", "Macbeth"],
    ["The game is up.", "Cymbeline"],
    ["Men of few words are the best men.", "King Henry V"],
    ["Out of the jaws of death.", "Taming of the Shrew"],
    ["Shall I compare thee to a summer's day?", "Sonnet 18"],
    ["That it should come to this!", "Hamlet"],
    ["Brevity is the soul of wit.", "Hamlet"],
    ["A horse! a horse! my kingdom for a horse!", "King Richard III"],
    ["I like not fair terms and a villain's mind.", "The Merchant of Venice"],
    ["If you wrong us, shall we not revenge?", "The Merchant of Venice"],
    [
      "The miserable have no other medicine but only hope.",
      "Measure for Measure",
    ],
    ["He will give the devil his due.", "King Henry IV"],
    ["Uneasy lies the head that wears a crown.", "King Henry IV"],
    ["Suspicion always haunts the guilty mind.", "King Henry IV"],
    ["Cry 'Havoc,' and let slip the dogs of war.", "Julius Caesar"],
    ["My salad days, when I was green in judgment...", "Antony and Cleopatra"],
    ["Be not afraid of greatness.", "Twelfth Night"],
    ["I come to bury Caesar, not to praise him.", "Julius Caesar"],
    ["Gallop apace, you fiery-footed steeds...", "Romeo and Juliet"],
    ["I can again thy former light restore...", "Othello"],
    ["Into this breathing world scarce half made up...", "Richard III"],
    ["Once more unto the breach, dear friends, once more...", "Henry V"],
    ["Disguise fair nature with hard-favor'd rage", "Henry V"],
    ["Tomorrow in the battle think on me.", "Richard III"],
    ["Upon my life, my lord, I'll undertake it.", "Richard III"],
    ["Ere I let fall the windows of mine eyes", "Richard III"],
    ["Let me sit heavy on thy soul tomorrow!", "Richard III"],
    ["And fall thy edgeless sword; despair, and die!", "Richard III"],
    ["Now are our brows bound up with glorious wreaths...", "Richard III"],
    ["Plots have I laid, inductions dangerous.", "Richard III"],
    ["He hearkens after prophecies and dreams.", "Richard III"],
    ["I know it pleaseth neither of us well.", "Richard III"],
    ["Hence! home, you idle creatures get you home.", "Julius Caesar"],
    ["Why, sir, cobble you.", "Julius Caesar"],
    ["O you hard hearts, you cruel men of Rome...", "Julius Caesar"],
    ["Why should that name be sounded more than yours?", "Julius Caesar"],
    ["Men at some times are masters of their fates.", "Julius Caesar"],
    ["Till then, my noble friend, chew upon this...", "Julius Caesar"],
    ["The angry spot doth glow on Caesar's brow.", "Julius Caesar"],
    ["Meantime we shall express our darker purpose...", "King Lear"],
    ["Come not between the dragon and his wrath.", "King Lear"],
    ["My life I never held but as a pawn...", "King Lear"],
    ["Legitimate Edgar, I must have your land...", "King Lear"],
    [
      "Another moon: but, O, methinks, how slow...",
      "A Midsummer Night's Dream",
    ],
    [
      "Thrice-blessed they that master so their blood...",
      "A Midsummer Night's Dream",
    ],
    [
      "Brief as the lightning in the collied night...",
      "A Midsummer Night's Dream",
    ],
    ["Mortality and mercy in Vienna...", "Measure for Measure"],
    ["Let there be some more test made of my metal...", "Measure for Measure"],
    ["I'll wait upon your honour.", "Measure for Measure"],
    ["Groping for trouts in a peculiar river.", "Measure for Measure"],
    [
      "All houses in the suburbs of Vienna must be plucked down.",
      "Measure for Measure",
    ],
    ["Fellow, why dost thou show me thus to the world?", "Measure for Measure"],
    ["Bear me to prison, where I am committed.", "Measure for Measure"],
  ]

  var index = Math.floor(Math.random() * quotes.length)

  return (
  <div className="content"><span className="stagedir">{quotes[index][0]}</span> - {quotes[index][1]}</div>
  )
}

export default RandomQuote
