import React from "react"
import { useIdentityContext } from "../../services/react-netlify-identity-gotrue"

const EmailConfirmation = () => {
  const identity = useIdentityContext()


  return (
    <div
      className={`has-text-left is-fluid has-background-primary ${
        identity && identity.provisionalUser ? "" : "is-hidden"
      }`}
    >
      <div className="notification has-background-sibredorange">
        <div className="has-text-white has-text-centered">
          You're almost finished signing up! Please check your email for a
          confirmation link. (Don't forget to check your spam folders just in case!)
        </div>
      </div>
    </div>
  )
}

export default EmailConfirmation
